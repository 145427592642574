import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import CustomLink from "../../components/link"
import Irstrat1 from "../../../static/img/irstrat/Irstrat1.png"
import Irstrat2 from "../../../static/img/irstrat/Irstrat2.png"
import irstrat3 from "../../../static/img/irstrat/irstrat3.jpg"
import aumento_liquidez from "../../../static/img/irstrat/aumento_liquidez.png"
import emision_deuda from "../../../static/img/irstrat/emision_deuda.png"
import incursion_mercados from "../../../static/img/irstrat/incursion_mercados.png"
import ipo from "../../../static/img/irstrat/ipo.png"
import renta_fija from "../../../static/img/irstrat/renta_fija.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Services"/>
            <Banner title="Services"/>
            <section className="propuesta mt-5">
                <div className="container">
                    <div className="section_title black_c">
                        <h2>Standard solutions</h2>
                        <span className="sec_border"></span>
                        <p className="f_16">We call “standard solutions” a package of services aimed at strengthening
                            the investor relations programs of Mexican debt and equity issuers (stocks, real estate
                            securities, capital development certificates, registered Eurobonds, among others). IRStrat
                            offers three standard solutions packages:</p>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat I: Focused on “image and identity”</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat II: Focused on “strategic message”</h5>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex align-items-start">
                                <i className="fa fa-check-square-o mt-1 mr-2"></i>
                                <h5 className="mb-2">IRStrat III: Focused on “intelligence and lobbying”</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <i>We believe that these three solution packages fully cover the key aspects of any IR
                                program, and their joint procurement is the best solution program for Mexican
                                issuers.</i>
                        </div>
                        <div className="col-md-6">
                            <i>We call them standard solutions because we believe that every issuer should adopt these
                                three cornerstones of Investor Relations for an optimal positioning and perception.</i>
                        </div>
                    </div>
                </div>
            </section>
            <section className="practice_area servicios mt-5 pt-4">
                <div className="container">
                    <ul className="list_style gallery-filter">
                        <li className="active" data-filter="*">All</li>
                        <li data-filter=".estandar">Standard solutions</li>
                        <li data-filter=".medidad">Tailor-made solutions</li>
                        {/*<li data-filter=".iredge">IR Edge</li>*/}
                    </ul>
                    <div id="gallery" className="row gallery_inner practice_inner">
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={Irstrat1} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_1">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_1">
                                    <h5>IRStrat I</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={Irstrat2} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_2">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_2">
                                    <h5>IRStrat II</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item estandar">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={irstrat3} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/irstrat_3">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/irstrat_3">
                                    <h5>IRStrat III</h5>
                                </CustomLink>
                            </div>
                        </div>

                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={aumento_liquidez} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/aumento_liquidez">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/aumento_liquidez">
                                    <h5>Liquidity Enchancement Program</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={emision_deuda} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/emision_deuda">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/emision_deuda"><h5>Debt Issuance Program</h5></CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={incursion_mercados} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/programa_incursion">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/programa_incursion"><h5>Market Entry Program</h5>
                                </CustomLink>
                            </div>
                        </div>

                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={ipo} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/programa_ipo_medida">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/programa_ipo_medida"><h5>IPO</h5>
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-md-4 gallery-item medidad">
                            <div className="practice_item gallery_post">
                                <div className="practice_image">
                                    <img src={renta_fija} alt={" "}/>
                                    <CustomLink className="read_btn" to="/servicios/programa_medida_renta">
                                        Read more
                                    </CustomLink>
                                </div>
                                <CustomLink to="/servicios/programa_medida_renta"><h5>Fixed Income Program</h5>
                                </CustomLink>

                            </div>
                        </div>
                        {/*<div className="col-md-4 gallery-item iredge">*/}
                        {/*<div className="practice_item gallery_post">*/}
                        {/*<div className="practice_image">*/}
                        {/*<img src={`../../img/irstrat/anuales.png`} alt={" "}/>*/}
                        {/*<CustomLink className="read_btn" to="/servicios/anual_sustentabilidad">*/}
                        {/*Read more*/}
                        {/*</CustomLink>*/}
                        {/*</div>*/}
                        {/*<CustomLink to="/servicios/anual_sustentabilidad"><h5>Annual and Sustainability*/}
                        {/*Report</h5>*/}
                        {/*</CustomLink>*/}
                        {/*</div>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
